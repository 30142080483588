import cn from "classnames";
import greenLight from "assets/icons/traffic-light-green.png";
import orangeLight from "assets/icons/traffic-light-orange.png";
import redLight from "assets/icons/traffic-light-red.png";
import styles from "./TrafficLight.module.scss";

interface ITrafficLightProps {
    value: number;
    displayText?: boolean;
    className?: string;
}

export const TrafficLight = ({ value, displayText, className }: ITrafficLightProps) => {
    const showText = displayText ?? false;

    let trafficLightImg: string;
    let text: string;

    if (value < -0.2 || value == null) {
        trafficLightImg = greenLight;
        text = "You have a green light because you are below the median of My ImpacT users, congratulations!";
    } else if (value >= -0.2 && value <= 0.2) {
        trafficLightImg = orangeLight;
        text = "You have an orange light because you are near to the median of My ImpacT users, you can do better!";
    }
    else {
        trafficLightImg = redLight;
        text = "You have a red light because you are above the median of My ImpacT users, time to take action!";
    }

    return (
        <div className={cn(styles.trafficLight, className)}>
            <img src={trafficLightImg} className={styles.icon} width={36} />
            {showText && <span className={styles.text}>{text}</span>}
        </div>
    );
};
