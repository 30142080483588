import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Dropdown, Field, Option, Radio, RadioGroup } from "@fluentui/react-components";
import styles from "./Welcome.module.scss";
import { useApiClient } from "contexts/ApiClient.context";
import { useNavigate } from "react-router-dom";
import { useStateContext, ActionType } from "store";
import { useData } from "@microsoft/teamsfx-react";
import { IDeviceReference } from "models/IDeviceReference";
import { PageLoader } from "components/pageLoader/PageLoader";
import { useContext, useState } from "react";
import { isProblemDetails } from "helpers/errorHelpers";
import { useMediaQuery } from "usehooks-ts";
import { TeamsFxContext } from "contexts/TeamsFx.context";

export const Welcome = () => {
    const { teamsUserCredential } = useContext(TeamsFxContext);
    const { apiClient } = useApiClient();
    const { state, dispatch } = useStateContext();

    // Define form validation schema
    const validationSchema = yup.object({
        computerId: yup.string().required().label("Computer"),
        mobilePhoneId: yup.string().required().label("Mobile phone"),
    });

    // Init form data
    const initialFormData: FormData = {
        computerId: null,
        mobilePhoneId: null,
        useTablet: "false",
        useHeadset: "false",
        useMouse: "false",
        useKeyboard: "false",
    };

    // Set form orientation based on screen size
    const fieldOrientation = useMediaQuery("(max-width: 767px)") ? "vertical" : "horizontal";

    // Handle form submission state
    const [submitting, setSubmitting] = useState(false);

    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();
    /** Save devices & navigate to overview */
    const handleSubmit = async (formData: FormData) => {
        setSubmitting(true);

        try {
            const userProfile = await teamsUserCredential!.getUserInfo();

            const newUser = {
                userPrincipalName: userProfile.preferredUserName,
                devices: {
                    computerId: Number.parseInt(formData.computerId!, 0),
                    mobilePhoneId: Number.parseInt(formData.mobilePhoneId!, 0),
                    tabletId: formData.useTablet === "true" ? state.devices.tablet!.deviceId : null,
                    headsetId: formData.useHeadset === "true" ? state.devices.headset!.deviceId : null,
                    mouseId: formData.useMouse === "true" ? state.devices.mouse!.deviceId : null,
                    keyboardId: formData.useKeyboard === "true" ? state.devices.keyboard!.deviceId : null,
                }
            };

            await apiClient!.post("/users/register", newUser);

            setSubmitting(false);
            navigate("/tab/overview?firstConnection=true");
        } catch (error: any) {
            console.error(error);

            if (isProblemDetails(error)) {
                setError(error.response?.data.detail ?? "An error occured");
            }

            setSubmitting(false);
        }
    };

    // Init formik for form validation
    const formik = useFormik({
        initialValues: initialFormData,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    // Fetch devices
    const { loading } = useData(async () => {
        const devices = await apiClient!.get<IDeviceReference>("/devices/references");
        dispatch!({ type: ActionType.SetDevices, payload: devices.data });
        return devices.data;

    }, { autoLoad: true });

    return (
        <div className={styles.welcome}>
            {loading && <PageLoader />}
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <h1 className="py-xl">Choose your devices</h1>
                <Field
                    label="Computer"
                    orientation={fieldOrientation}
                    size="large"
                    required={true}
                    validationMessage={formik.errors["computerId"]}
                    className={styles.field}>
                    <Dropdown
                        name="computerId"
                        aria-labelledby="computer"
                        placeholder="Select a computer"
                        size="large"
                        onOptionSelect={(_, data) => formik.setFieldValue("computerId", data.optionValue)}>
                        {
                            state?.devices?.computers?.map((computer) => (
                                <Option key={computer.deviceId} value={computer.deviceId.toString()}>
                                    {computer.name}
                                </Option>))
                        }
                    </Dropdown>
                </Field>

                <Field
                    label="Mobile phone"
                    orientation={fieldOrientation}
                    size="large"
                    required={true}
                    validationMessage={formik.errors["mobilePhoneId"]}
                    className={styles.field}>
                    <Dropdown
                        name="mobilePhoneId"
                        aria-labelledby="mobile-phone"
                        placeholder="Select a mobile phone"
                        size="large"
                        onOptionSelect={(_, data) => formik.setFieldValue("mobilePhoneId", data.optionValue)}>
                        {
                            state?.devices?.mobilePhones?.map((phone) => (
                                <Option key={phone.deviceId} value={phone.deviceId.toString()}>
                                    {phone.name}
                                </Option>))
                        }
                    </Dropdown>
                </Field>

                <Field label="Tablet" orientation="horizontal" size="large" className={styles.field}>
                    <RadioGroup
                        name="useTablet"
                        aria-labelledby="tablet"
                        layout="horizontal"
                        value={formik.values.useTablet}
                        onChange={formik.handleChange}>
                        <Radio value="true" label="Yes"></Radio>
                        <Radio value="false" label="No"></Radio>
                    </RadioGroup>
                </Field>

                <Field label="Headset" orientation="horizontal" size="large" className={styles.field}>
                    <RadioGroup
                        name="useHeadset"
                        aria-labelledby="headset"
                        layout="horizontal"
                        value={formik.values.useHeadset}
                        onChange={formik.handleChange}>
                        <Radio value="true" label="Yes"></Radio>
                        <Radio value="false" label="No"></Radio>
                    </RadioGroup>
                </Field>

                <Field label="Mouse" orientation="horizontal" size="large" className={styles.field}>
                    <RadioGroup
                        name="useMouse"
                        aria-labelledby="mouse"
                        layout="horizontal"
                        value={formik.values.useMouse}
                        onChange={formik.handleChange}>
                        <Radio value="true" label="Yes"></Radio>
                        <Radio value="false" label="No"></Radio>
                    </RadioGroup>
                </Field>

                <Field label="Keyboard" orientation="horizontal" size="large" className={styles.field}>
                    <RadioGroup
                        name="useKeyboard"
                        aria-labelledby="keyboard"
                        layout="horizontal"
                        value={formik.values.useKeyboard}
                        onChange={formik.handleChange}>
                        <Radio value="true" label="Yes"></Radio>
                        <Radio value="false" label="No"></Radio>
                    </RadioGroup>
                </Field>

                <div className="mt-xl text-right">
                    <Button type="submit" disabled={submitting}>Ok</Button>

                    <div className={styles.error}>
                        {error}
                    </div>
                </div>
            </form>
        </div>
    );
};

interface FormData {
    computerId: string | null;
    mobilePhoneId: string | null;
    useTablet: string;
    useHeadset: string;
    useMouse: string;
    useKeyboard: string;
}
