interface IPercentDiffProps {
    value: number | null;
    className?: string;
}

const formatter = Intl.NumberFormat("en-US", { style: "percent", signDisplay: "exceptZero", minimumFractionDigits: 0, maximumFractionDigits: 2 });

export const PercentDiff = ({ value, className }: IPercentDiffProps) => {
    const formatted = value == null ? "-" : formatter.format(value);
    return <span className={className}>{formatted}</span>;
};
