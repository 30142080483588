import { createContext, useContext, useReducer, ReactNode } from "react";
import { IDeviceReference } from "models/IDeviceReference";
import { Action, reducer } from "./reducer";
import { IEmissionEquivalence } from "models/IEmissionEquivalence";

interface State {
    devices: IDeviceReference;
    equivalences: IEmissionEquivalence[];
    equivalencesLoaded: boolean;
}

interface StoreContext {
    state: State;
    dispatch?: React.Dispatch<Action>;
}

const initialState: State = {
    devices: {
        computers: [],
        mobilePhones: [],
        tablet: null,
        mouse: null,
        keyboard: null,
        headset: null,
    },
    equivalences: [],
    equivalencesLoaded: false,
};

const storeContext = createContext<StoreContext>({ state: initialState });
const { Provider } = storeContext;

const useStateContext = () => useContext(storeContext);

const StateProvider = (props: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <Provider value={{state, dispatch}}>{props.children}</Provider>;
};

export { StateProvider, type State, useStateContext };
