import cn from "classnames";
import { ProgressBar } from "@fluentui/react-components";
import { useData } from "@microsoft/teamsfx-react";
import co2Icon from "assets/icons/co2-min.png";
import * as deviceIcons from "assets/icons/devices";
import { Banner } from "components/banner/Banner";
import { Card } from "components/card/Card";
import { Metric } from "components/metric/Metric";
import { DidYouKnow } from "components/title/DidYouKnow";
import { TipsToReduce } from "components/title/TipsToReduce";
import { useApiClient } from "contexts/ApiClient.context";
import { DeviceStat } from "./DeviceStat";
import styles from "./Devices.module.scss";
import { EmissionEquivalence } from "components/emissionEquivalence/EmissionEquivalence";
import { TrafficLight } from "components/trafficLight/TrafficLight";
import { IDeviceStat } from "models/IDeviceStat";

export const Devices = () => {

    // Get overview stats from API
    const { apiClient } = useApiClient();
    const { loading, data } = useData(async () => {
        const response = await apiClient!.get<IDeviceStat>("/devices");
        return response.data;
    }, { autoLoad: true });

    const devicesStats: IDeviceStat = data ?? {
        computerImpact: 0,
        mobilePhoneImpact: 0,
        headsetImpact: 0,
        tabletImpact: 0,
        keyboardImpact: 0,
        mouseImpact: 0,
        medianDiff: 0,
    };

    const total = (devicesStats.computerImpact ?? 0)
        + (devicesStats.headsetImpact ?? 0)
        + (devicesStats.tabletImpact ?? 0)
        + (devicesStats.keyboardImpact ?? 0)
        + (devicesStats.mobilePhoneImpact ?? 0)
        + (devicesStats.mouseImpact ?? 0);

    return (
        <div className="page">
            {loading && <ProgressBar className="progress-bar" thickness="large" shape="square" />}

            <Banner className="mb-md" height="fit-content" background="grayscale">
                <div>
                    <h1 className="my-0">My device annual ImpacT</h1>
                    <h2 className="flex gap-md justify-center align-center">
                        <img src={co2Icon} alt="co2" width={40} />
                        <span>Total: <Metric number={total} toCo2 /></span>
                    </h2>
                    <div className="body-1-long">
                        The environmental impact of digital technology at the individual level is mostly represented by devices (especially its <span className="font-weight-bold">manufacturing</span>).
                    </div>
                    <div className="body-1-long font-weight-bold">
                        Extending their lifetime is the most impactful action you can take at an individual level.
                    </div>
                </div>
            </Banner>

            <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-6 flex flex-column">
                    <TrafficLight value={devicesStats.medianDiff} displayText />
                    <EmissionEquivalence impact={total} />

                    <Card className={cn(styles.devices, "flex-grow-1")}>
                        <DeviceStat
                            icon={deviceIcons.laptop}
                            alt="laptop"
                            value={devicesStats.computerImpact}
                            className={styles.device} />
                        <div></div>
                        <DeviceStat
                            icon={deviceIcons.headset}
                            alt="headset"
                            value={devicesStats.headsetImpact ?? 0}
                            className={styles.device} />
                        <DeviceStat
                            icon={deviceIcons.tablet}
                            alt="tablet"
                            value={devicesStats.tabletImpact ?? 0}
                            className={styles.device} />
                        <div></div>
                        <DeviceStat
                            icon={deviceIcons.keyboard}
                            alt="keyboard"
                            value={devicesStats.keyboardImpact ?? 0}
                            className={styles.device} />
                        <DeviceStat
                            icon={deviceIcons.phone}
                            alt="phone"
                            value={devicesStats.mobilePhoneImpact}
                            className={styles.device} />
                        <div></div>
                        <DeviceStat
                            icon={deviceIcons.mouse}
                            alt="mouse"
                            value={devicesStats.mouseImpact ?? 0}
                            className={styles.device} />
                    </Card>
                </div>

                <div className="col-xs-12 col-md-6 col-lg-6">
                    <Card>
                        <DidYouKnow />
                        <h3>40 billion digital devices worldwide</h3>
                        <p className="body-1-long">
                            That's the number of phones, computers, tablets, games consoles, televisions, screens, etc. in
                            2020. And the trend is sharply accelerating.
                        </p>
                        <p className="body-1-long">
                            On an individual scale, it is the manufacture of digital equipment that weighs the most,
                            accounting for
                            <span className="font-weight-bold">
                                &nbsp;80% of the environmental footprint of digital technology.
                            </span>
                        </p>
                        <p className="body-1-long">
                            It is estimated that the average lifespan of a telephone worldwide is 2 years.
                        </p>

                        <p>A laptop weighing 62kgCO2 emits as much as travelling:</p>
                        <ul>
                            <li>270 km by plane</li>
                            <li>285 km by car</li>
                            <li>25,271 km by train</li>
                        </ul>
                    </Card>
                </div>

                <div className="col-12">
                    <Card>
                        <TipsToReduce></TipsToReduce>
                        <div className="body-1-long">
                            <ul>
                                <li>
                                    The priority is not to increase the number of digital devices you have.
                                    To do so, you can ask yourself: do I really need it?
                                </li>
                                <li>
                                    For the devices you do have, to extend their lifespan: protect your equipment
                                    (houses, screen protectors), repair, conserve (fight against fashion trends).
                                </li>
                            </ul>
                            <div className="font-style-italic">
                                It is estimated that the average lifespan of a telephone worldwide is 2 years,
                                when it is recommended to keep your phone for at least 5 years
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};
