import cn from "classnames";
import { PropsWithChildren } from "react";
import { useTeams } from "@microsoft/teamsfx-react";
import darkStyles from "./Banner.dark.module.scss";
import defaultStyles from "./Banner.module.scss";

interface IBannerProps {
    className?: string;
    background?: string;
    height?: string;
    minHeight?: string;
    image?: string;
}

export const Banner = (props: PropsWithChildren<IBannerProps>) => {
    const [{ themeString }] = useTeams();

    const bannerStyles = {
        height: props.height ?? "",
        minHeight: props.minHeight ?? "",
        backgroundImage: props.image ? `url(${props.image})` : "",
    };

    // Build styles
    const styles = {
        ...defaultStyles,
        ...(themeString === "dark" ? darkStyles : {}),
    };

    let backgroundVariant: string;
    if (props?.background === "grayscale") {
        backgroundVariant = themeString !== "default" ? "40" : "20";
    }
    else {
        backgroundVariant = themeString !== "default" ? "50" : "10";
    }

    // Build class names
    const classNames = cn(
        styles.bannerVars,
        styles.banner,
        { [`bg-${props.background}-${backgroundVariant}`]: props.background },
        { [styles.imgBanner]: props.image != null },
        props.className);

    return (
        <div style={bannerStyles} className={classNames}>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    );
};
