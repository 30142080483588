import { PropsWithChildren } from "react";
import { useTeams } from "@microsoft/teamsfx-react";
import cn from "classnames";

interface IOverviewContainerProps {
    className?: string;
}

export const OverviewContainer = (props: PropsWithChildren<IOverviewContainerProps>) => {
    const [{ themeString }] = useTeams();

    const theme = themeString === "default" ? "light" : "dark";

    const backgroundClass = theme === "light" ? "bg-grayscale-white" : "bg-grayscale-90";
    const className = cn("flex flex-column flex-grow-1 justify-space-between", backgroundClass, props.className);

    return (
        <div className={className} style={{flex: "1 1 100%"}}>
            {props.children}
        </div>
    );
};
