const config = {
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
    clientId: process.env.REACT_APP_CLIENT_ID,
    apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
    apiScopes: process.env.REACT_APP_API_SCOPES?.split(",") ?? [],
    appInsightsConnectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
    oneDriveLink: process.env.REACT_APP_ONE_DRIVE_LINK,
};

export default config;
