import cn from "classnames";
import { ProgressBar } from "@fluentui/react-components";
import { useData, useTeams } from "@microsoft/teamsfx-react";
import co2Icon from "assets/icons/co2-min.png";
import { laptop as laptopIcon, phone as phoneIcon } from "assets/icons/devices";
import * as msIcons from "assets/icons/microsoft";
import sustainableTechBlueIcon from "assets/icons/sustainable-tech/sustainabletech-logo-bluehorizontal.svg";
import sustainableTechWhiteIcon from "assets/icons/sustainable-tech/sustainabletech-logo-whitehorizontal.svg";
import overviewHeader2 from "assets/images/overview-header-2.jpg";
import overviewHeader from "assets/images/overview-header.jpg";
import { Banner } from "components/banner/Banner";
import { DisplayDate } from "components/date/DisplayDate";
import { Metric } from "components/metric/Metric";
import { PercentDiff } from "components/percentDiff/PercentDiff";
import { useApiClient } from "contexts/ApiClient.context";
import { bytesToGigabytes, secondsToHours } from "helpers/convertHelpers";
import { IOverviewStats } from "models/IOverviewStats";
import { Link } from "react-router-dom";
import darkStyles from "./Overview.dark.module.scss";
import defaultStyles from "./Overview.module.scss";
import { OverviewContainer } from "./OverviewContainer";
import { OverviewStats } from "./OverviewStats";
import { TrafficLight } from "components/trafficLight/TrafficLight";

export default function Overview() {
    // Get overview stats from API
    const { apiClient } = useApiClient();
    const [{ themeString }] = useTeams();

    // Build styles
    const styles = {
        ...defaultStyles,
        ...(themeString === "dark" ? darkStyles : {}),
    };

    const appIcon = themeString === "dark" ? sustainableTechWhiteIcon : sustainableTechBlueIcon;

    const { loading, data } = useData(async () => {
        const response = await apiClient!.get<IOverviewStats>("/overview");
        return response.data;
    }, { autoLoad: true });

    const overviewStats: IOverviewStats = data ?? {
        lastUpdate: new Date().toString(),
        communicationTotalImpact: 0,
        communicationTotalImpactDiff: null,
        communicationMedianDiff: 0,
        collaborationTotalImpact: 0,
        collaborationTotalImpactDiff: null,
        collaborationMedianDiff: 0,
        storageTotalImpact: 0,
        storageTotalImpactDiff: null,
        storageMedianDiff: 0,
        devicesTotalImpact: 0,
        devicesMedianDiff: 0,
        emailSent: 0,
        emailSentImpact: 0,
        emailStorageUsedBytes: 0,
        emailStorageUsedImpact: 0,
        oneDriveStorageUsedBytes: 0,
        oneDriveStorageUsedImpact: 0,
        sharePointOwnedSites: 0,
        sharePointOwnedSitesImpact: 0,
        teamsScreenShareDuration: 0,
        teamsScreenShareDurationImpact: 0,
        teamsVideoDuration: 0,
        teamsVideoDurationImpact: 0,
        computerImpact: 0,
        mobilePhoneImpact: 0,
    };

    const totalUse = overviewStats.communicationTotalImpact
        + overviewStats.collaborationTotalImpact
        + overviewStats.storageTotalImpact;

    const collaborationOthersImpact = overviewStats.communicationTotalImpact
        - overviewStats.emailStorageUsedImpact
        - overviewStats.emailSentImpact;

    return (
        <div className="page">
            {loading && <ProgressBar className="progress-bar" thickness="large" shape="square" />}

            <Banner height="fit-content" image={overviewHeader}>
                <h2 className="my-0">My Tech impact at Work</h2>
                <h3 className="flex gap-md align-center justify-center mb-0">
                    <span>By</span>
                    <img src={appIcon} height={50} alt="sustainable tech icon" />
                </h3>
            </Banner>

            <div className="row mt-0">
                <div className="col-xs-12 col-md-9 col-lg-9" style={{paddingTop: 8}}>
                    <Banner image={overviewHeader2}>
                        <h2 className="mt-0 mb-xs">Use</h2>
                        <div className="flex gap-md mb-xs justify-center align-center">
                            <img src={co2Icon} width={30} alt="CO2" />
                            <h3>Total: <Metric number={totalUse} toCo2 /></h3>
                        </div>

                        <div className={styles.bottomLeft}>
                            <div className="font-weight-semibold">
                                Data from last 30 days on <DisplayDate date={overviewStats.lastUpdate} format="DD/MM/YYYY" />
                            </div>
                            <div className="font-weight-semibold">Comparison vs last 30 days</div>
                        </div>
                    </Banner>

                    <div className="row mt-0 justify-space-evenly" style={{marginLeft: -16}}>
                        {/* Communication */}
                        <div className={"col-xs-12 col-sm-12 col-md-4 " + styles.subSection} style={{paddingTop: 8}}>
                            <Banner background="curacao">
                                <h3 className="mt-0 mb-sm">Communication</h3>
                                <span className="font-weight-bold">Total: <Metric number={overviewStats.communicationTotalImpact} toCo2 /></span>
                                <TrafficLight value={overviewStats.communicationMedianDiff} className="mt-sm" />
                                <PercentDiff className="heading-5 mt-sm" value={overviewStats.communicationTotalImpactDiff} />
                            </Banner>

                            <OverviewContainer>
                                <OverviewStats
                                    className="mt-md"
                                    description="Storage used"
                                    icon={msIcons.outlook}
                                    loading={loading}
                                    metric={overviewStats.emailStorageUsedImpact}
                                />

                                <OverviewStats
                                    description={overviewStats.emailSent + " email sent"}
                                    loading={loading}
                                    metric={overviewStats.emailSentImpact}
                                />

                                <OverviewStats
                                    description="Others"
                                    loading={loading}
                                    metric={collaborationOthersImpact}
                                />

                                <div className="flex justify-center my-sm">
                                    <Link className={styles.learnMore} to="/tab/communication">Learn more</Link>
                                </div>
                            </OverviewContainer>
                        </div>

                        {/* Collaboration */}
                        <div className={"col-xs-12 col-sm-12 col-md-4 " + styles.subSection} style={{paddingTop: 8}}>
                            <Banner background="blueberry">
                                <h3 className="mt-0 mb-sm">Collaboration</h3>
                                <span className="font-weight-bold">Total: <Metric number={overviewStats.collaborationTotalImpact} toCo2 /></span>
                                <TrafficLight value={overviewStats.collaborationMedianDiff} className="mt-sm" />
                                <PercentDiff className="heading-5 mt-sm" value={overviewStats.collaborationTotalImpactDiff} />
                            </Banner>

                            <OverviewContainer>
                                <OverviewStats
                                    className="mt-md"
                                    description={secondsToHours(overviewStats.teamsVideoDuration)?.toFixed(2) + " hours of video conferencing"}
                                    icon={msIcons.teams}
                                    loading={loading}
                                    metric={overviewStats.teamsVideoDurationImpact}
                                />

                                <OverviewStats
                                    description={secondsToHours(overviewStats.teamsScreenShareDuration)?.toFixed(2) + " hours of screen sharing"}
                                    loading={loading}
                                    metric={overviewStats.teamsScreenShareDurationImpact}
                                />

                                <div className="flex justify-center my-sm">
                                    <Link className={styles.learnMore} to="/tab/collaboration">Learn more</Link>
                                </div>
                            </OverviewContainer>
                        </div>

                        {/* Storage */}
                        <div className={"col-xs-12 col-sm-12 col-md-4 " + styles.subSection} style={{paddingTop: 8}}>
                            <Banner background="ice">
                                <h3 className="mt-0 mb-sm">Storage</h3>
                                <span className="font-weight-bold">Total: <Metric number={overviewStats.storageTotalImpact} toCo2 /></span>
                                <TrafficLight value={overviewStats.storageMedianDiff} className="mt-sm" />
                                <PercentDiff className="heading-5 mt-sm" value={overviewStats.storageTotalImpactDiff} />
                            </Banner>

                            <OverviewContainer>
                                <OverviewStats
                                    className="mt-md"
                                    description={bytesToGigabytes(overviewStats.oneDriveStorageUsedBytes)?.toFixed(2) + " Gb of storage in personal OneDrive"}
                                    icon={msIcons.oneDrive}
                                    loading={loading}
                                    metric={overviewStats.oneDriveStorageUsedImpact}
                                />
                                <OverviewStats
                                    description={overviewStats.sharePointOwnedSites + " of owned sites"}
                                    icon={msIcons.sharePoint}
                                    loading={loading}
                                    metric={overviewStats.sharePointOwnedSitesImpact}
                                />

                                <div className="flex justify-center my-sm">
                                    <Link className={styles.learnMore} to="/tab/storage/onedrive">Learn more</Link>
                                </div>
                            </OverviewContainer>
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-md-3 col-lg-3 flex flex-column" style={{paddingTop: 8}}>
                    <Banner image={overviewHeader2}>
                        <h2 className="mt-0 mb-xs">Manufacturing</h2>
                        <div className="flex gap-md mb-xs justify-center align-center">
                            <img src={co2Icon} width={30} alt="CO2" />
                            <h3>Total: <Metric number={overviewStats.devicesTotalImpact} toCo2 /></h3>
                        </div>
                        <div className={cn(styles.bottomLeft, "font-weight-semibold")}>Annual impact</div>
                    </Banner>

                    <div className="row mt-0 flex-grow-1" style={{marginLeft: -16}}>
                        <div className={"col-xs-12 " + styles.subSection} style={{paddingTop: 8}}>
                            <Banner background="grayscale">
                                <h3 className="mt-0 mb-sm">Devices</h3>
                                <span className="font-weight-bold">Total: <Metric number={overviewStats.devicesTotalImpact} toCo2 /></span>
                                <TrafficLight value={overviewStats.devicesMedianDiff} className="mt-sm" />
                                <span className="heading-5 mt-sm">-</span>
                            </Banner>

                            <OverviewContainer>
                                <OverviewStats
                                    className="mt-md"
                                    icon={laptopIcon}
                                    loading={loading}
                                    metric={overviewStats.computerImpact}
                                />

                                <OverviewStats
                                    icon={phoneIcon}
                                    loading={loading}
                                    metric={overviewStats.mobilePhoneImpact}
                                />

                                <div className="flex justify-center my-sm">
                                    <Link className={styles.learnMore} to="/tab/devices">Learn more</Link>
                                </div>
                            </OverviewContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
