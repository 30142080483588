import { createContext, useContext, ReactNode, useMemo } from "react";
import { AxiosInstance, BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";

import config from "config";
import { TeamsFxContext } from "./TeamsFx.context";

type ApiClientContext = { apiClient?: AxiosInstance };

// Create initial context
const initialContext: ApiClientContext = { apiClient: undefined };
const ApiClientReactContext = createContext(initialContext);

/**
 * ApiClientProvider provides an API client instance to child components.
 * @param props React props
 * @returns React provider component
 */
const ApiClientProvider = (props: { children: ReactNode }) => {
    const { teamsUserCredential } = useContext(TeamsFxContext);

    // Create API client context (memoized to avoid re-creation on every render)
    const contextValue = useMemo(() => {
        if (!config.apiEndpoint) {
            throw new Error("API endpoint is not configured.");
        }

        if (!teamsUserCredential) {
            return { apiClient: undefined };
        }

        // Create API client with bearer token provied by TeamsFx SDK
        const apiClient = createApiClient(
            config.apiEndpoint,
            new BearerTokenAuthProvider(async () => {
                try {
                    // Get token silently
                    const auth = await teamsUserCredential.getToken("");
                    return auth!.token;
                } catch (error) {
                    console.warn("Failed to get token silently. Initiating login.", error);

                    // Get token by initiating login
                    await teamsUserCredential.login("");
                    const auth = await teamsUserCredential.getToken("");
                    return auth!.token;
                }
            })
        );

        return { apiClient };
    }, [teamsUserCredential]);

    return (
        <ApiClientReactContext.Provider value={contextValue}>
            {props.children}
        </ApiClientReactContext.Provider>
    );
};

const useApiClient = () => useContext(ApiClientReactContext);

export { ApiClientReactContext, ApiClientProvider, useApiClient };
