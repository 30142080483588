import { PropsWithChildren } from "react";
import { useTeams } from "@microsoft/teamsfx-react";
import styles from "./Card.module.scss";

interface ICardProps {
    className?: string;
    style?: React.CSSProperties;
}

export const Card = (props: PropsWithChildren<ICardProps>) => {
    const [{ themeString }] = useTeams();

    const theme = themeString === "default" ? "light" : "dark";
    const className = `${styles.card} ${theme === "light" ? "bg-grayscale-white" : "bg-grayscale-90"} ${props?.className || ""}`;

    return (
        <div className={className} style={props.style}>
            {props.children}
        </div>
    );
};
