import styles from "./DeviceStat.module.scss";
import { Metric } from "components/metric/Metric";

export interface IDeviceStatProps {
    className?: string;
    icon: string;
    alt: string;
    value: number;
}

export const DeviceStat = ({ icon, alt, value, className }: IDeviceStatProps) => {
    return (
        <div className={className}>
            <img src={icon} alt={alt} className={styles.icon} />
            <span className="font-weight-bold">
                <Metric number={value} toCo2 />
            </span>
        </div>
    );
};
