import { useContext } from "react";
import { ProgressBar } from "@fluentui/react-components";
import { useData } from "@microsoft/teamsfx-react";
import { Banner } from "components/banner/Banner";
import { ApiClientReactContext } from "contexts/ApiClient.context";
import { ISharePointStorageStats } from "models/ISharePointStorageStats";
import co2Icon from "assets/icons/co2-min.png";
import { Metric } from "components/metric/Metric";
import { Card } from "components/card/Card";
import { DisplayDate } from "components/date/DisplayDate";
import { TipsToReduce } from "components/title/TipsToReduce";
import { DidYouKnow } from "components/title/DidYouKnow";
import { SharePointSites } from "./SharePointSites";
import { EmissionEquivalence } from "components/emissionEquivalence/EmissionEquivalence";
import { TrafficLight } from "components/trafficLight/TrafficLight";

export default function SharePoint() {
    // Get SharePoint stats from API
    const { apiClient } = useContext(ApiClientReactContext);
    const { loading, data } = useData(async () => {
        const response = await apiClient!.get<ISharePointStorageStats>("/storage/sharepoint");
        return response.data;
    }, { autoLoad: true });

    const sharePointStorageStats: ISharePointStorageStats = data ?? {
        lastUpdate: new Date().toString(),
        totalImpact: 0,
        medianDiff: 0,
        sites: [],
    };

    return (
        <div className="page">
            { loading && <ProgressBar className="progress-bar" thickness="large" shape="square" /> }

            <Banner className="mb-md" height="fit-content" background="ice">
                <div>
                    <h1 className="my-0">My SharePoint storage ImpacT</h1>
                    <h2 className="flex gap-md justify-center align-center mb-sm">
                        <img src={co2Icon} alt="co2" width={40} />
                        <span>Total: <Metric number={sharePointStorageStats.totalImpact} toCo2 /></span>
                    </h2>
                    <div className="body-1-long">
                        The environmental impact of SharePoint is mostly in the storage space used for SharePoint sites.
                        <span className="font-weight-bold">&nbsp;Consider cleaning.</span>
                    </div>
                </div>
            </Banner>

            {/* Sites table */}
            <div className="col-xs-12">
                <h3 className="text-center mt-0">My activity on SharePoint the past 30 days</h3>
                <div>Last update on <DisplayDate date={sharePointStorageStats.lastUpdate} format="DD/MM/YYYY" /></div>

                <SharePointSites sites={sharePointStorageStats.sites ?? []} />
            </div>

            <div className="row mt-md">
                <div className="col-xs-12 col-md-6 col-lg-6">
                    <TrafficLight value={sharePointStorageStats.medianDiff} displayText />
                    <EmissionEquivalence impact={sharePointStorageStats.totalImpact} />

                    <Card className="h-100">
                        <TipsToReduce></TipsToReduce>
                        <ul className="body-1-long">
                            <li>
                                Regularly review and clean up your SharePoint sites to remove unnecessary files
                                (the one that haven't being consulted for several months or years).
                            </li>
                            <li>
                                To prevent some SharePoint sites from being abandoned,
                                add at least a second owner to administer the site during your absences/departures.
                            </li>
                        </ul>
                    </Card>
                </div>

                <div className="col-xs-12 col-md-6 col-lg-6">
                    <Card>
                        <DidYouKnow></DidYouKnow>
                        <h3>15,000 km is the average distance an email before being delivered to the recipient's inbox</h3>
                        <p className="body-1-long">
                            Deposit the documents to share with your team on Sharepoint. Do not send them by email. In this way, you can avoid having data travel
                            around 15,000 km before it reaches the recipient. 15,000 km is the average distance an email travels through numerous servers around the
                            world before being delivered to the recipient's inbox.
                        </p>
                    </Card>
                </div>
            </div>
        </div>
    );
}
