import { Chart, ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTeams } from "@microsoft/teamsfx-react";
import { getGlobalChartJsTheme } from "helpers/themeHelpers";
import { IOneDriveStorageStats } from "models/IOneDriveStorageStats";
import { Chart as ReactChart } from "react-chartjs-2";

interface IOneDriveStorageShareProps {
    stats: IOneDriveStorageStats;
    className?: string;
}

const gCo2Formatter = new Intl.NumberFormat("en-US", { style: "decimal", maximumFractionDigits: 0 });
const kgCo2Formatter = new Intl.NumberFormat("en-US", { style: "decimal", maximumFractionDigits: 1 });

export const OneDriveStorageShare = ({ stats, className }: IOneDriveStorageShareProps) => {
    const [{ themeString }] = useTeams();
    const chartTheme = getGlobalChartJsTheme(themeString);

    // Calculate inactive files (floored to 0)
    const inactiveFiles = stats.fileCount - stats.activeFileCount > 0 ? stats.fileCount - stats.activeFileCount : 0;

    const data = {
        labels: [
            "Active files",
            "Inactive files",
        ],
        datasets: [{
            data: [stats.activeFileCount, inactiveFiles],
            backgroundColor: ["#123f75", "#7aa5d3"],
            hoverOffset: 4
        }],
    };

    const doughnutOptions: ChartOptions<"doughnut"> = {
        cutout: "75%",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
            },
            datalabels: {
                color: chartTheme.color,
                font: { weight: "bold", },
                backgroundColor: chartTheme.backgroundColor,
                borderRadius: 4,
                display: (context) => {
                    return !context.active;
                },
                formatter: (value, context) => {
                    const label = context.chart.data.labels![context.dataIndex] as string;
                    return `${label.replace(" files", "")}: ${value}`;
                }
            },
        },
    };

    const doughnutPlugins = [
        ChartDataLabels,
        {
            id: "doughnut-center-text",
            beforeDraw: (chart: any) => {
                const width = chart.width;
                const height = chart.height;
                const ctx = chart.ctx;

                ctx.restore();

                const co2FontSize = (height / 200).toFixed(2);
                ctx.font = `bold ${co2FontSize}em sans-serif`;
                ctx.textBaseline = "top";

                let co2Text: string;
                if (stats.oneDriveStorageUsedImpact >= 1000) {
                    co2Text = kgCo2Formatter.format(stats.oneDriveStorageUsedImpact) + " kgCO2";
                }
                else {
                    co2Text = gCo2Formatter.format(stats.oneDriveStorageUsedImpact) + " gCO2";
                }

                const co2TextX = Math.round((width - ctx.measureText(co2Text).width) / 2);
                const co2TextY = height / 2;

                ctx.fillStyle = Chart.defaults.color;
                ctx.fillText(co2Text, co2TextX, co2TextY - 25);

                const gbFontSize = (height / 300).toFixed(2);
                const gbText = `${(stats.oneDriveStorageUsedBytes / Math.pow(10, 9)).toFixed(2)} Gb`;
                const gbTextX = Math.round((width - ctx.measureText(gbText).width) / 2);
                const gbTextY = height / 2 + 40;
                ctx.font = `normal ${gbFontSize}em sans-serif`;

                ctx.fillStyle = Chart.defaults.color;
                ctx.fillText(gbText, gbTextX + 20, gbTextY);
                ctx.fillText("of storage used", gbTextX - 10, gbTextY + 30);

                ctx.save();
            }
        },
    ];

    return (
        <div className={className}>
            <div style={{height: "314px"}}>
                <ReactChart type="doughnut" options={doughnutOptions} plugins={doughnutPlugins} data={data} redraw={true} />
            </div>
            <div className="text-center mt-md">
                <i>Active file: file modified in last 30 days</i>
            </div>
        </div>
    );
};

