import { Chart } from "chart.js";
import { useEffect } from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@fluentui/react";
import { FluentProvider, teamsDarkTheme, teamsLightTheme, tokens } from "@fluentui/react-components";
import { app } from "@microsoft/teams-js";
import { useTeams, useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import config from "config";
import { ApiClientProvider } from "contexts/ApiClient.context";
import { TeamsFxContext } from "contexts/TeamsFx.context";
import { StateProvider } from "store/store";
import { ProtectedRoute } from "./protectedRoute/ProtectedRoute";
import { reactPlugin } from "plugins/ApplicationInsightsPlugin";
import Tab from "./Tab";
import { Welcome } from "./welcome/Welcome";
import "./App.scss";
import { darkTheme, lightTheme } from "themes";
import { getGlobalChartJsTheme } from "helpers/themeHelpers";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
    // Initialize the Microsoft Teams SDK
    const [{ theme, themeString }] = useTeams();
    const { loading, teamsUserCredential } = useTeamsUserCredential({
        initiateLoginEndpoint: config.initiateLoginEndpoint!,
        clientId: config.clientId!,
    });

    // Initial app load
    useEffect(() => {
        loading && app.initialize().then(() => {
            // Hide the loading indicator.
            app.notifySuccess();
        });
    }, [loading]);

    // Set the theme (for fluent ui 8 & 9 components)
    const fluentUI9Theme = themeString !== "default" ? teamsDarkTheme : teamsLightTheme;
    const fluentUI8Theme = themeString !== "default" ? darkTheme : lightTheme;

    // Set the Chart.js theme colors
    const chartTheme = getGlobalChartJsTheme(themeString);
    Chart.defaults.color = chartTheme.color;
    Chart.defaults.borderColor = chartTheme.borderColor;

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
                <StateProvider>
                    <FluentProvider
                        theme={fluentUI9Theme}
                        style={{ background: tokens.colorNeutralBackground3 }}>
                        <ThemeProvider theme={fluentUI8Theme}>
                            <ApiClientProvider>
                                <Router>
                                    {!loading && (
                                        <Routes>
                                            <Route path="/welcome" element={<Welcome />} />
                                            <Route element={<ProtectedRoute />}>
                                                <Route path="/tab" element={<Navigate to={"/tab/overview"} />} />
                                                <Route path="/tab/*" element={<Tab />} />
                                            </Route>
                                            <Route path="*" element={<Navigate to={"/tab/overview"} />}></Route>
                                        </Routes>
                                    )}
                                </Router>
                            </ApiClientProvider>
                        </ThemeProvider>
                    </FluentProvider>
                </StateProvider>
            </TeamsFxContext.Provider>
        </AppInsightsContext.Provider>
    );
}
