import { createTheme } from "@fluentui/react";

export const lightTheme = createTheme({
    palette: {
        themePrimary: "#0078d4",
        themeLighterAlt: "#eff6fc",
        themeLighter: "#deecf9",
        themeLight: "#c7e0f4",
        themeTertiary: "#71afe5",
        themeSecondary: "#2b88d8",
        themeDarkAlt: "#106ebe",
        themeDark: "#005a9e",
        themeDarker: "#004578",
        neutralLighterAlt: "#e7e7e7",
        neutralLighter: "#e3e3e3",
        neutralLight: "#dadada",
        neutralQuaternaryAlt: "#cbcbcb",
        neutralQuaternary: "#c2c2c2",
        neutralTertiaryAlt: "#bababa",
        neutralTertiary: "#a19f9d",
        neutralSecondary: "#605e5c",
        neutralPrimaryAlt: "#3b3a39",
        neutralPrimary: "#323130",
        neutralDark: "#201f1e",
        black: "#000000",
        white: "#eeeeee",
    },
});


export const darkTheme = createTheme({
    palette: {
        themePrimary: "#96a8fa",
        themeLighterAlt: "#06070a",
        themeLighter: "#181b28",
        themeLight: "#2d324b",
        themeTertiary: "#5a6596",
        themeSecondary: "#8494dc",
        themeDarkAlt: "#a0b1fa",
        themeDark: "#afbdfb",
        themeDarker: "#c4cefc",
        neutralLighterAlt: "#1e1e1e",
        neutralLighter: "#1d1d1d",
        neutralLight: "#1c1c1c",
        neutralQuaternaryAlt: "#1a1a1a",
        neutralQuaternary: "#191919",
        neutralTertiaryAlt: "#181818",
        neutralTertiary: "#c8c8c8",
        neutralSecondary: "#d0d0d0",
        neutralPrimaryAlt: "#dadada",
        neutralPrimary: "#ffffff",
        neutralDark: "#f4f4f4",
        black: "#f8f8f8",
        white: "#1f1f1f",
    },
});
