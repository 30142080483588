import { PropsWithChildren } from "react";
import action from "assets/icons/action-button.png";
import arrow from "assets/icons/down-arrow.png";

interface ITipsToReduceProps {
    className?: string;
}

export const TipsToReduce = (props: PropsWithChildren<ITipsToReduceProps>) => {
    const className = `flex gap-xl pa-md text-center align-center justify-center ${props?.className ?? ""}`;

    return (
        <div className={className}>
            <img src={action} alt="action" width={60} />
            <h2 className="my-auto">Tips to reduce my carbone footprint</h2>
            <img src={arrow} alt="arrow" width={60} />
        </div>
    );
};
