import { PropsWithChildren } from "react";
import { bytesToGigabytes, secondsToHours } from "helpers/convertHelpers";

interface IMetricProps {
    className?: string;
    number: number;
    toCo2?: boolean;
    toGigabytes?: boolean;
    toHours?: boolean;
    emptyText?: string | undefined;
}

const defaultProps: Partial<IMetricProps> = {
    emptyText: "-",
};

const gCo2Formatter = new Intl.NumberFormat("en-US", { style: "decimal", maximumFractionDigits: 0 });
const kgCo2Formatter = new Intl.NumberFormat("en-US", { style: "decimal", maximumFractionDigits: 1 });

export const Metric = (propsIn: PropsWithChildren<IMetricProps>) => {
    const props = { ...defaultProps, ...propsIn };

    let formattedNumber: string | undefined;

    if (props.toHours) {
        formattedNumber = secondsToHours(props.number)?.toFixed(2);
    }
    else if (props.toCo2 && props.number >= 1000) {
        formattedNumber = kgCo2Formatter.format(props.number / 1000) + " kgCO2";
    }
    else if (props.toCo2) {
        formattedNumber = gCo2Formatter.format(props.number) + " gCO2";
    }
    else if (props.toGigabytes) {
        formattedNumber = bytesToGigabytes(props.number)?.toFixed(2);
    }
    else {
        formattedNumber = props.number.toFixed(2);
    }

    return (
        <span className={props.className}>
            {formattedNumber ?? props.emptyText}
        </span>
    );
};
