import { PropsWithChildren } from "react";
import dayjs from "dayjs";

interface IDisplayDateProps {
    className?: string;
    date: string | null;
    format?: string;
}

export const DisplayDate = (props: PropsWithChildren<IDisplayDateProps>) => {
    const className = `font-weight-bold ${props?.className ?? ""}`;

    const formattedDate = () => {
        if (props.date == null) {
            return "-";
        }

        const format = props?.format ?? "DD/MM/YYYY HH:mm:ss";

        const date = dayjs(props.date);

        if (!date.isValid()) {
            return null;
        }

        return date.format(format);
    };

    return (
        <span className={className}>
            {formattedDate()}
        </span>
    );
};
