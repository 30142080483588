import { Skeleton, SkeletonItem } from "@fluentui/react-components";
import { Metric } from "components/metric/Metric";

interface IOverviewStatsProps {
    className?: string;
    description?: string;
    icon?: string;
    iconSize?: string;
    loading?: boolean;
    metric: number;
}

export const OverviewStats = (props: IOverviewStatsProps) => {
    const className = `text-center ${props.className ?? ""}`;
    const size = props.iconSize ?? "30px";

    const { description, icon, loading, metric } = props;

    let lines = null;
    if (loading) {
        lines =
            <Skeleton>
                {description && <SkeletonItem />}
            </Skeleton>;
    }
    else {
        lines =
            <>
                <div className="flex flex-wrap gap-sm justify-center align-center">
                    <Metric className="heading-5 font-weight-bold" number={metric} toCo2 />
                </div>
                { description && <div>{description}</div> }
            </>;
    }

    return (
        <div className={className}>
            {
                icon &&
                <img
                    height={size}
                    width={size}
                    src={icon}
                    className="mb-xs"/>
            }
            { lines }
        </div>
    );
};
