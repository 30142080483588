import { Dispatch } from "react";
import { AxiosInstance } from "@microsoft/teamsfx";
import { IEmissionEquivalence } from "models/IEmissionEquivalence";
import { Action, ActionType } from "./reducer";

export const loadEquivalences = async (apiClient: AxiosInstance, dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.SetEquivalencesLoaded, payload: false });

    const equivalences = await apiClient.get<IEmissionEquivalence[]>("/equivalences");

    dispatch({ type: ActionType.SetEquivalences, payload: equivalences.data });
    dispatch({ type: ActionType.SetEquivalencesLoaded, payload: true });
};
