import { useEffect, useState } from "react";
import { useStateContext } from "store";
import { Spinner } from "@fluentui/react-components";
import { useApiClient } from "contexts/ApiClient.context";
import { IEmissionEquivalence } from "models/IEmissionEquivalence";
import styles from "./EmissionEquivalence.module.scss";
import { loadEquivalences } from "store/actions";

interface IEmissionEquivalenceProps {
    impact: number;
}

interface IDisplayState {
    equivalence: IEmissionEquivalence | null;
    icon: string | null;
    icon2: string | null;
}

export const EmissionEquivalence = ({ impact }: IEmissionEquivalenceProps) => {
    const { apiClient } = useApiClient();
    const { state, dispatch } = useStateContext();

    const [displayState, setDisplayState] = useState<IDisplayState>({
        equivalence: null,
        icon: null,
        icon2: null,
    });

    // Load icons dynamically
    const loadIcons = async (equivalence: IEmissionEquivalence | null) => {
        let icon = null;
        let icon2 = null;

        if (equivalence?.iconName != null) {
            const img = await import(`assets/icons/equivalences/${equivalence.iconName}`);
            icon = img.default;
        }

        if (equivalence?.iconName2 != null) {
            const img = await import(`assets/icons/equivalences/${equivalence.iconName2}`);
            icon2 = img.default;
        }

        return { icon, icon2 };
    };

    // Load equivalences if not already loaded in store
    useEffect(() => {
        const load = async () => {
            if (!state.equivalencesLoaded) {
                await loadEquivalences(apiClient!, dispatch!);
            }

            // Find equivalence
            const equivalence = state.equivalences.find(e => impact >= e.lowerBound && impact < e.upperBound) ?? null;
            const icons = await loadIcons(equivalence);

            setDisplayState({
                equivalence,
                icon: icons.icon,
                icon2: icons.icon2,
            });
        };

        load();
    }, [state.equivalencesLoaded, impact]);

    return (
        <div className={styles.equivalence}>
            <p className="my-sm">You emit per month as much as:</p>
            { !state.equivalencesLoaded && <Spinner tabIndex={0} /> }
            {
                state.equivalencesLoaded &&
                <>
                    <p className="flex justify-center align-center my-sm">
                        {
                            displayState.icon &&
                            <span className={styles.icon}>
                                <img src={displayState.icon} width={24}></img>
                            </span>
                        }
                        <span className={styles.text}>{displayState.equivalence?.text}</span>
                    </p>
                    <p className="flex justify-center align-center my-sm">
                        {
                            displayState.icon2 &&
                            <span className={styles.icon}>
                                <img src={displayState.icon2} width={24}></img>
                            </span>
                        }
                        <span className={styles.text}>{displayState.equivalence?.text2}</span>
                    </p>
                </>
            }
        </div>
    );
};
