import dayjs from "dayjs";

export const getDateInRange = (start: string | Date, stop: string | Date, step: number) => {
    const dateArray = [];
    let current = dayjs(start);

    while (current.isBefore(stop) || current.isSame(stop)) {
        dateArray.push(current.toDate());
        current = dayjs(current).add(step, "day");
    }

    return dateArray;
};
