import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import config from "config";

const validKey = config.appInsightsConnectionString != null && config.appInsightsConnectionString.length > 0;

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        disableTelemetry: !validKey,
        connectionString: validKey ? config.appInsightsConnectionString : "InstrumentationKey=00000000-0000-0000-0000-000000000000",
        extensions: [reactPlugin],
        extensionConfig: {},
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
    }
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
