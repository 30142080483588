import cn from "classnames";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, TableColumnDefinition, createTableColumn, Link, Tooltip } from "@fluentui/react-components";
import { useTeams } from "@microsoft/teamsfx-react";
import { DisplayDate } from "components/date/DisplayDate";
import { Metric } from "components/metric/Metric";
import { ISharePointStorageSiteStats } from "models/ISharePointStorageSiteStats";
import defaultStyles from "./SharePointSites.module.scss";
import darkStyles from "./SharePointSites.dark.module.scss";

interface ISharePointSitesProps {
    className?: string;
    sites: ISharePointStorageSiteStats[];
}

export const SharePointSites = (props: ISharePointSitesProps) => {
    const [{ themeString }] = useTeams();

    // Build styles
    const styles = {
        ...defaultStyles,
        ...(themeString === "dark" ? darkStyles : {}),
    };

    // Define reusable table header
    const TableHeader = ({ children }: { children: ReactNode }): JSX.Element =>
        <div className={styles.headerCell}>{children}</div>;

    // Define reusable table header
    const TableCell = ({ children }: { children: ReactNode }): JSX.Element =>
        <div className={styles.rowCell}>{children}</div>;

    // Define table columns
    const columns: TableColumnDefinition<ISharePointStorageSiteStats>[] = [
        createTableColumn<ISharePointStorageSiteStats>({
            columnId: "siteUrl",
            renderHeaderCell: () => <TableHeader>Site URL</TableHeader>,
            renderCell: (site) =>
                <TableCell>
                    <Link href={site.siteUrl} target="_blank" className={styles.siteLink}>{site.siteUrl}</Link>
                </TableCell>,
            compare: (a, b) => a.siteUrl.localeCompare(b.siteUrl),
        }),
        createTableColumn<ISharePointStorageSiteStats>({
            columnId: "lastActivityDate",
            renderHeaderCell: () => <TableHeader>Last activity date</TableHeader>,
            renderCell: (site) =>
                <TableCell>
                    <DisplayDate date={site.lastActivityDate} format="DD/MM/YYYY" />
                </TableCell>,
            compare: (a, b) => dayjs(a.lastActivityDate).diff(dayjs(b.lastActivityDate)),
        }),
        createTableColumn<ISharePointStorageSiteStats>({
            columnId: "fileCount",
            renderHeaderCell: () => <TableHeader>Files</TableHeader>,
            renderCell: (site) => <TableCell>{site.fileCount}</TableCell>,
            compare: (a, b) => a.fileCount - b.fileCount,
        }),
        createTableColumn<ISharePointStorageSiteStats>({
            columnId: "activeFileCount",
            renderHeaderCell: () =>
                <TableHeader>
                    <Tooltip content="File modified in last 30 days" relationship="description">
                        <span>
                            Active files <span className="font-weight-medium">&#x1F6C8;</span>
                        </span>
                    </Tooltip>
                </TableHeader>,
            renderCell: (site) => <TableCell>{site.activeFileCount}</TableCell>,
            compare: (a, b) => a.activeFileCount - b.activeFileCount,
        }),
        createTableColumn<ISharePointStorageSiteStats>({
            columnId: "storageUsed",
            renderHeaderCell: () => <TableHeader>Used storage (Gb)</TableHeader>,
            renderCell: (site) =>
                <TableCell>
                    <Metric number={site.storageUsedBytes} toGigabytes />
                </TableCell>,
            compare: (a, b) => a.storageUsedBytes - b.storageUsedBytes,
        }),
    ];

    // Get top 5 sites
    const topSites: ISharePointStorageSiteStats[] = structuredClone(props.sites)
        .sort((a: ISharePointStorageSiteStats, b: ISharePointStorageSiteStats) => b.storageUsedBytes - a.storageUsedBytes)
        .slice(0, 5);

    return (
        <DataGrid
            items={topSites}
            columns={columns}
            sortable
            defaultSortState={{ sortColumn: "storageUsed", sortDirection: "descending" }}
            className={cn(props.className, styles.sitesVars)}>
            <DataGridHeader className={cn(styles.headerRow, styles.headerRow)}>
                <DataGridRow>
                    {({ renderHeaderCell }) => (
                        <DataGridHeaderCell className={styles.headerCellBox}>{renderHeaderCell()}</DataGridHeaderCell>
                    )}
                </DataGridRow>
            </DataGridHeader>
            <DataGridBody<ISharePointStorageSiteStats> className={cn(styles.body, styles.body)}>
                {({ item, rowId }) => (
                    <DataGridRow<ISharePointStorageSiteStats> key={rowId}>
                        {({ renderCell }) => (
                            <DataGridCell>{renderCell(item)}</DataGridCell>
                        )}
                    </DataGridRow>
                )}
            </DataGridBody>
        </DataGrid>
    );
};
