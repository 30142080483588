import { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useData } from "@microsoft/teamsfx-react";
import { ApiClientReactContext } from "contexts/ApiClient.context";
import { PageLoader } from "components/pageLoader/PageLoader";

export const ProtectedRoute = () => {
    const { apiClient } = useContext(ApiClientReactContext);
    const navigate = useNavigate();

    const { loading, data } = useData(async () => {
        const response = await apiClient!.get<boolean>("/users/registered");

        if (!response?.data || response?.status !== 200) {
            navigate("/welcome");
        }

        return response?.data;
    }, { autoLoad: true });

    return (
        <>
            { (loading) && <PageLoader /> }
            { !loading && data && <Outlet /> }
        </>
    );
};
