import "chart.js/auto";
import type { ChartData, ChartOptions } from "chart.js";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { useContext } from "react";
import { Chart } from "react-chartjs-2";
import { useData } from "@microsoft/teamsfx-react";
import { ApiClientReactContext } from "contexts/ApiClient.context";
import { ICollaborationHistoryStats } from "models/ICollaborationHistoryStats";
import { Spinner } from "@fluentui/react-components";
import { getDateInRange } from "helpers/dateHelpers";
import { secondsToHours } from "helpers/convertHelpers";

export interface ICollaborationHistoryProps {
    from: string | null;
}

dayjs.extend(minMax);

export const CollaborationHistory = (props: ICollaborationHistoryProps) => {
    // Get history stats from API
    const { apiClient } = useContext(ApiClientReactContext);

    const from = dayjs(props.from).subtract(7 * 12, "day").toDate();
    const to = dayjs(props.from).toDate();

    const { loading, data } = useData(async () => {
        if (props.from == null) {
            return null;
        }

        const queryFrom = dayjs(from).format("YYYY-MM-DD");
        const response = await apiClient!.get<ICollaborationHistoryStats>(`/collaboration/history/${queryFrom}`);
        return response.data;
    }, { autoLoad: true });

    const formatDate = (date: string | Date) => dayjs(date).format("DD/MM/YYYY");

    let chartData: ChartData = {
        labels: [],
        datasets: [],
    };

    const options: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: { y: { beginAtZero: true } },
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    if (data && data.global.length > 0) {
        // Start graph on mininmum global date
        const minimum = dayjs.min(data.global.map(point => dayjs(point.date)));
        const labels = getDateInRange(minimum!.toDate(), to, 7).map(date => formatDate(date));

        // Generate dataset for user and global history
        const userDataset: any[] = labels.map(label => {
            const value = data.user.find(point => formatDate(point.date) === label)?.videoCallDuration ?? null;

            return {
                x: label,
                y: secondsToHours(value),
            };
        });

        const globalDataset: any[] = labels.map(label => {
            const value = data.global.find(point => formatDate(point.date) === label)?.videoCallDuration ?? null;

            return {
                x: label,
                y: secondsToHours(value),
            };
        });

        chartData = {
            labels,
            datasets: [
                {
                    data: userDataset,
                    label: "Mine (hours)",
                },
                {
                    data: globalDataset,
                    label: "Average MyImpacT users (hours)",
                }
            ],
        };
    }

    return (
        <div style={{ position: "relative", height: "250px" }}>
            {loading && <Spinner />}
            {
                !loading && data != null &&
                <Chart type="line" options={options} data={chartData} />
            }
        </div>
    );
};
