import { useData } from "@microsoft/teamsfx-react";
import { useContext, useState } from "react";
import { Banner } from "components/banner/Banner";
import { ApiClientReactContext } from "contexts/ApiClient.context";
import { ProgressBar } from "@fluentui/react-components";
import { Card } from "components/card/Card";
import { ICommunicationStats } from "models/ICommunicationStats";
import co2Icon from "assets/icons/co2-min.png";
import { DidYouKnow } from "components/title/DidYouKnow";
import { TipsToReduce } from "components/title/TipsToReduce";
import { Metric } from "components/metric/Metric";
import { DisplayDate } from "components/date/DisplayDate";
import { EmissionEquivalence } from "components/emissionEquivalence/EmissionEquivalence";
import { CommunicationHistory } from "./CommunicationHistory";
import { TrafficLight } from "components/trafficLight/TrafficLight";

export default function Communication() {
    const [communicationStats, setCommunicationStats] = useState<ICommunicationStats>({
        lastUpdate: null,
        totalImpact: 0,
        emailSent: 0,
        emailSentImpact: 0,
        emailReceived: 0,
        emailReceivedImpact: 0,
        emailStored: 0,
        emailStoredImpact: 0,
        emailStorageUsedBytes: 0,
        emailStorageUsedImpact: 0,
        medianDiff: 0
    });

    // Get overview stats from API
    const { apiClient } = useContext(ApiClientReactContext);
    const { loading } = useData(async () => {
        const response = await apiClient!.get<ICommunicationStats>("/communication");
        setCommunicationStats(response.data);
    }, { autoLoad: true });

    return (
        <div className="page">
            { loading && <ProgressBar className="progress-bar" thickness="large" shape="square" /> }

            <Banner className="mb-md" height="fit-content" background="curacao">
                <div>
                    <h1 className="my-0">My Communication ImpacT</h1>
                    <h2 className="flex gap-md align-center justify-center mb-sm">
                        <img src={co2Icon} alt="co2" width={40} />
                        <span>Total: <Metric number={communicationStats.totalImpact} toCo2 /></span>
                    </h2>

                    <div className="body-1-long">
                        The environmental impact of Outlook is mainly in the storage and sending of emails. Promote the use of Teams and OneDrive.
                    </div>
                    <div className="body-1-long font-weight-bold">
                        Sending an email with an attachment can represent up to 50g of CO2.
                    </div>
                </div>
            </Banner>

            <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-6">
                    <TrafficLight value={communicationStats.medianDiff} displayText />
                    <EmissionEquivalence impact={communicationStats.totalImpact} />

                    {/* Stats */}
                    <div>Last update on <DisplayDate date={communicationStats.lastUpdate} format="DD/MM/YYYY" /></div>
                    <Card>
                        <div className="flex flex-column gap-md">
                            <div>
                                <b>My activity on Outlook</b>
                                <ul>
                                    <li>Outlook Storage: <Metric number={communicationStats.emailStorageUsedImpact} toCo2 /> (<Metric number={communicationStats.emailStorageUsedBytes} toGigabytes /> Gb)</li>
                                    <li>Number of emails: <Metric number={communicationStats.emailStoredImpact} toCo2 /> ({communicationStats.emailStored} emails)</li>
                                </ul>
                            </div>

                            <div>
                                <b>The past 30th days</b>
                                <ul>
                                    <li>Email sent: <Metric number={communicationStats.emailSentImpact} toCo2 /> ({communicationStats.emailSent} emails)</li>
                                    <li>Email received: <Metric number={communicationStats.emailReceivedImpact} toCo2 /> ({communicationStats.emailReceived} emails)</li>
                                </ul>
                            </div>
                        </div>
                    </Card>

                    {/* Graph */}
                    <div className="body-1-short mt-md mb-xs text-center">
                        Emails stored per week
                    </div>
                    <CommunicationHistory key={communicationStats.lastUpdate} from={communicationStats.lastUpdate} />
                </div>

                <div className="col-xs-12 col-md-6 col-lg-6">
                    <Card className="h-100">
                        <DidYouKnow />
                        <h3>In 2023, humanity will send the equivalent of 62 000 Paris-New York round-flights in emails!</h3>
                        <p className="body-1-long">
                            Indeed, 350 billions of emails exchanged everyday represents 105 000 tones of Co2. As an example, in England, short unnecessary emails contribute as much CO2 each year as 3,334 diesel cars. If each British people would abstain from sending out a “Thank you” email, we would conserve more than 16 000 tons of CO2 per year.
                        </p>
                    </Card>
                </div>

                <div className="col-12">
                    <Card>
                        <TipsToReduce />
                        <ul className="body-1-long">
                            <li>Reduce the number of recipients (multiplying the number of recipients of an e-mail by 10 means multiplying its environmental impact by 4)</li>
                            <li>Don't send attachments, prefer links</li>
                            <li>Cut down on the number of unnecessary emails, memes…</li>
                            <li>Use the company's instant messaging system if you can rather than sending an email</li>
                            <li>Clean up your emails, including spam and deleted messages and unsubscribe from newsletters you don't read</li>
                        </ul>
                    </Card>
                </div>
            </div>
        </div>
    );
}
