import { useData } from "@microsoft/teamsfx-react";
import { useContext, useState } from "react";
import { Banner } from "components/banner/Banner";
import { ApiClientReactContext } from "contexts/ApiClient.context";
import { ProgressBar } from "@fluentui/react-components";
import { Card } from "components/card/Card";
import { IOneDriveStorageStats } from "models/IOneDriveStorageStats";
import co2Icon from "assets/icons/co2-min.png";
import { Metric } from "components/metric/Metric";
import { TipsToReduce } from "components/title/TipsToReduce";
import { DidYouKnow } from "components/title/DidYouKnow";
import { DisplayDate } from "components/date/DisplayDate";
import { OneDriveStorageHistory } from "./OneDriveStorageHistory";
import { OneDriveStorageShare } from "./OneDriveStorageShare";
import config from "config";
import styles from "./OneDrive.module.scss";
import { EmissionEquivalence } from "components/emissionEquivalence/EmissionEquivalence";
import { TrafficLight } from "components/trafficLight/TrafficLight";

export default function OneDrive() {
    const [oneDriveStorageStats, setOneDriveStorageStats] = useState<IOneDriveStorageStats>({
        lastUpdate: new Date().toString(),
        totalImpact: 0,
        oneDriveStorageUsedBytes: 0,
        oneDriveStorageUsedImpact: 0,
        fileCount: 0,
        activeFileCount: 0,
        medianDiff: 0,
    });

    // Get overview stats from API
    const { apiClient } = useContext(ApiClientReactContext);
    const { loading } = useData(async () => {
        const response = await apiClient!.get<IOneDriveStorageStats>("/storage/onedrive");
        setOneDriveStorageStats(response.data);
    }, { autoLoad: true });

    return (
        <div className="page">
            { loading && <ProgressBar className="progress-bar" thickness="large" shape="square" /> }

            <Banner className="mb-md" height="fit-content" background="ice">
                <div>
                    <h1 className="my-0">My personal OneDrive storage ImpacT</h1>
                    <h2 className="flex gap-md justify-center align-center mb-sm">
                        <img src={co2Icon} alt="co2" width={40} />
                        <span>Total: <Metric number={oneDriveStorageStats.totalImpact} toCo2 /></span>
                    </h2>
                    <div className="body-1-long">
                        The environmental impact of OneDrive is mainly in document storage.
                    </div>
                    <div className="body-1-long font-weight-bold">
                        By default, OneDrive saves the last 500 versions of a document, which can multiply its weight by 5.
                    </div>
                </div>
            </Banner>

            <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-6">
                    <h3 className="text-center mt-0">My activity on OneDrive the past 30 days</h3>
                    <p>Last update on <DisplayDate date={oneDriveStorageStats.lastUpdate} format="DD/MM/YYYY" /></p>
                    <OneDriveStorageShare stats={oneDriveStorageStats} className={styles.doughnutGraph} />

                    <Card className="mt-xl">
                        <TipsToReduce />
                        <div className="body-1-long">
                            Don't forget to regularly clean out your OneDrive: prioritize deleting heavier documents. <br />
                            To identify them:

                            <ul>
                                <li>
                                    Open&nbsp;
                                    <a href={config.oneDriveLink} target="_blank" rel="noreferrer" className={styles.oneDriveLink}>OneDrive</a>
                                    &nbsp;in your browser
                                </li>
                                <li>Go to the "My files" tab in your left-hand sidebar</li>
                                <li>Click on "File size" and then on "from largest to smallest“</li>
                            </ul>

                            <div className="font-style-italic">
                                The top files are the large files in OneDrive, which you can sort through if you no longer need them
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6">
                    <div>
                        <TrafficLight value={oneDriveStorageStats.medianDiff} displayText />
                        <EmissionEquivalence impact={oneDriveStorageStats.totalImpact} />

                        {/* Graph */}
                        <div className="body-1-short text-center">
                            OneDrive storage per week
                        </div>
                        <OneDriveStorageHistory
                            className={styles.lineGraph}
                            key={oneDriveStorageStats.lastUpdate}
                            from={oneDriveStorageStats.lastUpdate} />

                        <Card className="mt-md">
                            <DidYouKnow />
                            <h3>A single data center can consume the equivalent electricity of 50,000 homes!</h3>
                            <p className="body-1-long">
                                And there are around 8000 data centers in the world, which makes <b>the cloud a greater carbon footprint than the airline industry.</b>
                                Indeed, cables, fibre optic tubes, cooling, water pipes or computer servers are some of the elements that makes the cloud running, relying themselves on electricity, water, metals, minerals or rare earth elements : <b>real-life earth materials to get stuff dematerialized.</b>
                            </p>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}
