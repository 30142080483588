import { UnreachableCaseError } from "errors/UnreachableCaseError";
import { type State } from "./store";

export enum ActionType {
    SetDevices = "SET_DEVICES",
    SetEquivalences = "SET_EQUIVALENCES",
    SetEquivalencesLoaded = "SET_EQUIVALENCES_LOADED",
}

export type Action =
    { type: ActionType.SetDevices, payload: State["devices"] } |
    { type: ActionType.SetEquivalences, payload: State["equivalences"] } |
    { type: ActionType.SetEquivalencesLoaded, payload: State["equivalencesLoaded"] };

export const reducer = (state: State, action: Action): State => {
    const type = action.type;
    switch (type) {
    case ActionType.SetDevices:
        return { ...state, devices: action.payload };
    case ActionType.SetEquivalences:
        return { ...state, equivalences: action.payload };
    case ActionType.SetEquivalencesLoaded:
        return { ...state, equivalencesLoaded: action.payload };
    default:
        throw new UnreachableCaseError(type);
    }
};
