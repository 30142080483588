import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components";
import { INavLink, INavLinkGroup, Nav } from "@fluentui/react/lib/Nav";
import { useTeams } from "@microsoft/teamsfx-react";
import devicesIcon from "assets/icons/devices-min.png";
import oneDriveIcon from "assets/icons/microsoft/onedrive.svg";
import outlookIcon from "assets/icons/microsoft/outlook.svg";
import sharePointIcon from "assets/icons/microsoft/sharepoint.svg";
import teamsIcon from "assets/icons/microsoft/teams.svg";
import sustainableMiniIcon from "assets/icons/sustainable-tech/sustainabletech-logo-mini.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import cn from "classnames";
import { useApiClient } from "contexts/ApiClient.context";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Collaboration from "./collaboration/Collaboration";
import Communication from "./communication/Communication";
import { Devices } from "./devices/Devices";
import Overview from "./overview/Overview";
import OneDrive from "./storage/OneDrive";
import SharePoint from "./storage/SharePoint";
import darkStyles from "./Tab.dark.module.scss";
import defaultStyles from "./Tab.module.scss";

/** Find navigation link matching URL path */
const getMatchingLinkKey = (pathname: string, links: INavLink[]): string => {
    const flattenedLinks = links.flatMap(link => link.links ?? [link]);
    return flattenedLinks.find(link => link.url === pathname)?.key ?? "overview";
};

export default function Tab() {
    const [{ themeString }] = useTeams();
    const { apiClient } = useApiClient();

    let sidenavDisplayedInMobile = false;

    // Build styles
    const styles = {
        ...defaultStyles,
        ...(themeString === "dark" ? darkStyles : {}),
    };

    // Display message if first connection
    const [firstConnection, setFirstConnection] = useState(window.location.href.includes("firstConnection=true"));
    const getUrl = (url: string) => `${url}${firstConnection ? "?firstConnection=true" : ""}`;

    // Build side navigation
    const navLinkGroups: INavLinkGroup[] = [{
        links: [
            {
                name: "Overview",
                url: getUrl("/tab/overview"),
                key: "overview",
                target: "_self",
                iconProps: {
                    style: {
                        height: "auto",
                    },
                    imageProps: {
                        src: sustainableMiniIcon,
                        width: 30,
                        className: styles.overviewIcon,
                    }
                },
            },
            {
                name: "Communication",
                url: getUrl("/tab/communication"),
                key: "communication",
                target: "_self",
                iconProps: {
                    style: { height: "auto", },
                    imageProps: {
                        src: outlookIcon,
                        width: 30,
                    }
                }
            },
            {
                name: "Collaboration",
                url: getUrl("/tab/collaboration"),
                key: "collaboration",
                target: "_self",
                iconProps: {
                    style: { height: "auto", },
                    imageProps: {
                        src: teamsIcon,
                        width: 30,
                    }
                }
            },
            {
                name: "Storage",
                url: getUrl("/tab/storage"),
                expandAriaLabel: "Expand Storage section",
                collapseAriaLabel: "Collapse Storage section",
                disabled: true,
                isExpanded: true,
                links: [
                    {
                        name: "OneDrive",
                        url: getUrl("/tab/storage/onedrive"),
                        key: "onedrive",
                        target: "_self",
                        iconProps: {
                            style: {
                                height: "auto",
                            },
                            imageProps: {
                                src: oneDriveIcon,
                                width: 30,
                            }
                        }
                    },
                    {
                        name: "SharePoint",
                        url: getUrl("/tab/storage/sharepoint"),
                        key: "sharepoint",
                        target: "_self",
                        iconProps: {
                            style: {
                                height: "auto",
                            },
                            imageProps: {
                                src: sharePointIcon,
                                width: 30,
                            }
                        }
                    },
                ]
            },
            {
                name: "Devices",
                url: getUrl("/tab/devices"),
                key: "devices",
                target: "_self",
                iconProps: {
                    style: { height: "auto", },
                    imageProps: {
                        src: devicesIcon,
                        width: 30,
                    }
                }
            }
        ],
    }];

    // Handle navigation
    const navigate = useNavigate();
    const onLinkClick = (event: React.MouseEvent | undefined, item: INavLink | undefined) => {
        hideMobileSidenav();
        event?.preventDefault();
        navigate(item?.url ?? "/tab/overview");
    };

    const onStopCollectionClick = async () => {
        hideMobileSidenav();
        await apiClient?.post("/users/unregister");
        navigate("/welcome");
    };

    const onClickOnMobileTrigger = () => {
        sidenavDisplayedInMobile = !sidenavDisplayedInMobile;
        if (sidenavDisplayedInMobile) {
            sidenavRef.current?.classList.add(styles.mobileVisibile);
        } else {
            sidenavRef.current?.classList.remove(styles.mobileVisibile);
        }
    };

    const hideMobileSidenav = () => {
        sidenavDisplayedInMobile = false;
        sidenavRef.current?.classList.remove(styles.mobileVisibile);
    };

    const reloadPageEventListener = () => {
        const page = document.getElementsByClassName("page")[0];
        if (page) {
            page.removeEventListener("click", hideMobileSidenav);
            page.addEventListener("click", hideMobileSidenav);
        }
    };

    // Update current link on navigation
    const { pathname } = useLocation();
    const matchingKey = getMatchingLinkKey(pathname, navLinkGroups[0].links);

    // Scroll to top on navigation
    const sidenavRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        contentRef.current?.scrollTo(0, 0);
        reloadPageEventListener();
    }, [pathname]);

    reloadPageEventListener();

    return (
        <div className={cn(styles.tabVars, styles.tab)}>

            <div ref={sidenavRef} className={styles.sidenav}>
                <Nav groups={navLinkGroups} selectedKey={matchingKey} onLinkClick={onLinkClick} />
                <Dialog>
                    <DialogTrigger disableButtonEnhancement>
                        <Button className={styles.bottom}>
                            Stop data collection
                        </Button>
                    </DialogTrigger>

                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Are you sure you want to stop data collection</DialogTitle>
                            <DialogContent>
                                By clicking on this button, you delete all your data collected by the application.
                                If you wish to return to the application, your data will be reset to zero.
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Close</Button>
                                </DialogTrigger>
                                <Button appearance="primary" onClick={onStopCollectionClick}>Confirm</Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            </div>
            <div className={styles.mobileTrigger} onClick={onClickOnMobileTrigger}>
                <Button style={{minWidth: 32}}>
                    <MenuIcon style={{width: 20}} />
                </Button>
            </div>
            <div ref={contentRef} className={styles.content}>
                <Routes>
                    <Route path="/overview" element={<Overview />} />
                    <Route path="/communication" element={<Communication />} />
                    <Route path="/collaboration" element={<Collaboration />} />
                    <Route path="/storage/onedrive" element={<OneDrive />} />
                    <Route path="/storage/sharepoint" element={<SharePoint />} />
                    <Route path="/devices" element={<Devices />} />
                </Routes>
            </div>

            <Dialog open={firstConnection} onOpenChange={(_, data) => setFirstConnection(data.open)}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>
                            We are preparing your data, please come back
                        </DialogTitle>
                        <DialogContent>
                            <DialogBody>
                                <DialogContent>
                                    For privacy and eco-conception reasons, we need to import your data directly from Microsoft.
                                    You'll receive a notification once it's ready (in about 5 minutes).
                                </DialogContent>
                            </DialogBody>
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="primary">Ok</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
}
