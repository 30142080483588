import { PropsWithChildren } from "react";
import styles from "./DidYouKnow.module.scss";
import lightBulb from "assets/icons/lightbulb-min.png";

interface IDidYouKnowProps {
    className?: string;
}

export const DidYouKnow = (props: PropsWithChildren<IDidYouKnowProps>) => {
    const className = `flex gap-xl align-center justify-center pa-md ${styles.didYouKnow} ${props?.className ?? ""}`;

    return (
        <div className={className}>
            <h2 className="text-center">Did you know ?</h2>
            <img src={lightBulb} alt="light bulb" width={60} />
        </div>
    );
};
