import { teamsDarkTheme, teamsLightTheme } from "@fluentui/react-components";

export const getGlobalChartJsTheme = (theme: string) => {
    const fluentUI9Theme = theme !== "default" ? teamsDarkTheme : teamsLightTheme;

    return {
        backgroundColor: fluentUI9Theme.colorNeutralBackground1,
        borderColor: fluentUI9Theme.colorNeutralStroke1,
        color: fluentUI9Theme.colorNeutralForeground1,
    };
};
