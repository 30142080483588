import { Spinner } from "@fluentui/react-components";
import styles from "./PageLoader.module.scss";

export const PageLoader = () => {
    return (
        <div className={styles.container}>
            <Spinner size="large" label="Loading..." tabIndex={0} />
        </div>
    );
};
