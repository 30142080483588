import { useData } from "@microsoft/teamsfx-react";
import { useContext, useState } from "react";
import { Banner } from "components/banner/Banner";
import { ApiClientReactContext } from "contexts/ApiClient.context";
import { ProgressBar } from "@fluentui/react-components";
import { Card } from "components/card/Card";
import { ICollaborationStats } from "models/ICollaborationStats";
import co2Icon from "assets/icons/co2-min.png";
import { Metric } from "components/metric/Metric";
import { DidYouKnow } from "components/title/DidYouKnow";
import { TipsToReduce } from "components/title/TipsToReduce";
import { DisplayDate } from "components/date/DisplayDate";
import { CollaborationHistory } from "./CollaborationHistory";
import { EmissionEquivalence } from "components/emissionEquivalence/EmissionEquivalence";
import { TrafficLight } from "components/trafficLight/TrafficLight";

export default function Collaboration() {
    const [collaborationStats, setCollaborationStats] = useState<ICollaborationStats>({
        lastUpdate: new Date().toString(),
        totalImpact: 0,
        teamsScreenShareDuration: 0,
        teamsScreenShareDurationImpact: 0,
        teamsVideoDuration: 0,
        teamsVideoDurationImpact: 0,
        teamsAudioDuration: 0,
        teamsAudioDurationImpact: 0,
        medianDiff: 0,
    });

    // Get overview stats from API
    const { apiClient } = useContext(ApiClientReactContext);
    const { loading } = useData(async () => {
        const response = await apiClient!.get<ICollaborationStats>("/collaboration");
        setCollaborationStats(response.data);
    }, { autoLoad: true });

    return (
        <div className="page">
            { loading && <ProgressBar className="progress-bar" thickness="large" shape="square" /> }

            <Banner className="mb-md" height="fit-content" background="blueberry">
                <div>
                    <h1 className="my-0">My Collaboration ImpacT</h1>
                    <h2 className="flex gap-md justify-center align-center mb-sm">
                        <img src={co2Icon} alt="co2" width={40} />
                        <span>Total: <Metric number={collaborationStats.totalImpact} toCo2 /></span>
                    </h2>
                    <div className="body-1-long">
                        The environmental impact of Teams is especially in the use of calls, screen sharing and video conferencing which are very consuming in energy.
                    </div>
                    <div className="body-1-long font-weight-bold">
                        1 hour of videoconferencing can represent up to 1000 g of Co2.
                    </div>
                </div>
            </Banner>

            <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-6">
                    <TrafficLight value={collaborationStats.medianDiff} displayText />
                    <EmissionEquivalence impact={collaborationStats.totalImpact} />

                    {/* Stats */}
                    <div>Last update on <DisplayDate date={collaborationStats.lastUpdate} format="DD/MM/YYYY" /></div>

                    <Card>
                        <div className="flex flex-column gap-md">
                            <div>
                                <b>The past 30th days</b>
                                <ul>
                                    <li>Video call duration: <Metric number={collaborationStats.teamsVideoDurationImpact} toCo2 /> (<Metric number={collaborationStats.teamsVideoDuration} toHours /> hours)</li>
                                    <li>Screen sharing duration: <Metric number={collaborationStats.teamsScreenShareDurationImpact} toCo2 /> (<Metric number={collaborationStats.teamsScreenShareDuration} toHours /> hours)</li>
                                    <li>Audio call duration: <Metric number={collaborationStats.teamsAudioDurationImpact} toCo2 /> (<Metric number={collaborationStats.teamsAudioDuration} toHours /> hours)</li>
                                </ul>
                            </div>
                        </div>
                    </Card>

                    {/* Graph */}
                    <div className="body-1-short mt-md mb-xs text-center">
                        Video call duration per week
                    </div>

                    <CollaborationHistory key={collaborationStats.lastUpdate} from={collaborationStats.lastUpdate} />
                </div>

                <div className="col-xs-12 col-md-6 col-lg-6">
                    <Card className="h-100">
                        <DidYouKnow />
                        <h3>Turning off your camera in video calls could cut carbon emissions by 96%!</h3>
                        <p className="body-1-long">
                            A new estimates that an hour of streaming or videoconferencing emits between 150 and 1 000 grams of carbon dioxide. It also uses up to 12 liters of water and an area of land around the size of an iPad mini.
                        </p>
                        <p className="body-1-long">
                            Streaming videos in standard definition rather than high definition may also result in an 86% environmental impact reduction.
                        </p>
                    </Card>
                </div>

                <div className="col-12">
                    <Card>
                        <TipsToReduce />
                        <ul className="body-1-long">
                            <li>If video and screen sharing are not needed, a phone call will consume less electricity</li>
                            <li>Give priority to audio rather than video exchanges (start and end with the camera then disactivate it)</li>
                            <li>Promote face-to-face meetings when possible (for example, if you work in the same building)</li>
                            <li>Use Wifi rather than 3G or 4G can reduce carbon impact by 3</li>
                            <li>Think carefully before creating a new Teams group as it automatically creates an associated SharePoint site</li>
                            <li>Consider deleting Teams group that you no longer use (if you own those teams)</li>
                        </ul>
                    </Card>
                </div>
            </div>
        </div>
    );
}
